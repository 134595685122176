import style from './terms.module.css'
function terms () {

    return (
        <div className={style.terms}>
            <div className={style.layeronediv}>
                <div className={style.layeronetitle}>
                        <p>
                        TRILLOAN INC. TERMS
                        </p>
                        <p>
                        AND CONDITIONS
                        </p>
                </div>   
                </div>   
                <div className={style.layeronetitlewide}>
                        <p>
                        TRILLOAN INC. TERMS AND
                        </p>
                        <p>
                       CONDITIONS
                        </p>
                </div>   

                <div className={style.mothercontent}>
                <div className={style.layeronetitle2}>
                    <span>
                    I. DEFINITION/SPECIAL TERMS 
                    </span>
                </div>   
             <div className={style.center}>
                <div className={style.layeronetitle3}>
                    <span>
                    1.1 trilLOAN User - Refers to an individual who uses the trilLOAN service (“Service”) and whose mobile number is registered in the Mobile App refers also to the Principal Account owner. 
                    </span>
                </div>   
                </div>
              
                <div className={style.layeronetitle3}>
                    <span>
                    1.2 Biller - Refers to any accredited company authorized by trilLOAN to accept bills payment using the Mobile App Service in behalf of trilLOAN and Bill Aggregator partner. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.3 Linked Accounts - Refers to trilLOAN supported Banks, Debit / Credit Cards and E-Wallets linked to trilLOAN user’s account and will be used as source option to make payments and withdraw funds from the user’s pending remittance transaction(s). 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.4 Funds Collection / Cash-In / Deposit - The process of collecting / debiting funds from the user's chosen linked account to be able to make payments on certain transactions. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.5 Cash-Out / Withdrawal - Refers to the process of crediting / disbursing funds to the user's linked account to be able to claim a remittance transaction. 
                    </span>
                </div>   
                <div className={style.layeronetitle3}>
                    <span>
                    1.5 Cash-Out / Withdrawal - Refers to the process of crediting / disbursing funds to the user's linked account to be able to claim a remittance transaction. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.6 Message - Refers to communication messaging sent through push notifications within the Mobile App and via Mobile Chat. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.7 Banking Transactions - Service that allows trilLOAN users to enjoy the convenience of withdrawing and depositing of their bank funds through the trilLOAN Mobile App. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.8 Peer to Peer Transfer - Refers to the funds transfer transactions made by trilLOAN user to trilLOAN user. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.9 Online Chat - Refers to communications services through mobile chat. This service allows registered users to communicate with fellow trilLOAN users through messaging functionalities.  
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.10 trilLOAN Inc. - An online payments collection company. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.11 trilLOAN User's Account - refers to an account that that is owned by a paychat customer in the trilLOAN Inc. system. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.12 trilLOAN App - Mobile application that serves as the interface between the trilLOAN user and his trilLOAN Account. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.13 trilLOAN Merchants - Entities (separate from a trilLOAN User) that provide services to trilLOAN Users. Each will have a designated bank account to which he/she may send his/her funds to. 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    1.14 Phone Messaging App - Refers to software or application that enables messages to be sent and received. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                   1.15 Peso Value - Refers to the equivalent monetary value in Philippine Peso (Php) used as a basis for all account transactions in the Philippines. 
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    II. TRILLOAN ACCOUNT 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN Account is associated to its customers and is used as a reference to transact with another trilLOAN account within the trilLOAN software platform. It does not maintain any funds for its subscribers. 
                    </span>
                </div>  
                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. may suspend or terminate the use of the Service for whatever reason it deems fit. 
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    III. ENROLLED ACCOUNTS 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    3.1 A user can transact using the trilLOAN Account through the Linked Accounts or via accredited Cash-in Centres or accredited Merchants. Users have the option to choose a list of existing linked accounts the user has added in his Mobile App. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    3.2 The value that has been cashed-in into the trilLOAN Account is also withdrawn at full amount through participating ATMs Bank Partners or other accredited money out channels, subject to withdrawal amount limits, and fees as may be applicable. The applicable charges and fees shall be automatically deducted from the balance in the account.  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    3.3 trilLOAN may impose minimum and/or maximum amounts per transaction or per account depending on business needs and applicable law, rules and regulations. 
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    IV. BILLS PAYMENT 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    Payment of bills does not require a printed receipt from the Biller. Once the transaction has been authorized and consummated, trilLOAN Inc. shall not be held liable for any non-performance of services, defects, damages, and after-sales services of such services, any error in the amount keyed-in or entered, and/or any other dispute between the biller and the trilLOAN User arising from such payment. The reference number in the Transaction history/Message confirmation shall be the Account Holder’s proof of payment. 
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    V. MERCHANT 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    The Service is accepted nationwide by any accredited Merchant partners honoring transactions. In the event that the Service is not honored by the Merchant or transactions declined or not authorized, the trilLOAN User agrees to hold trilLOAN Inc. free from any liability, claim or damages. The trilLOAN User shall not hold trilLOAN Inc. responsible for any defective product or service purchased through the Service.
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    VI. ACTIVATION AND DEACTIVATION OF TRILLOAN SERVICE FEATURES  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. may introduce other Service features. Moreover, it may also deactivate existing features at its option at any time. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. may opt to suspend or terminate a trilLOAN user Account Holder’s privilege to use a particular feature whenever it deems appropriate and without prior notice, particularly, but not limited to occasions when there are fraudulent transactions or suspicious activity. In such cases, trilLOAN Inc. may charge a fee upon reactivation of the said feature, as per request of the user. The approval of the request shall be the sole option of trilLOAN Inc after investigation has been done.  
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    VII. FEES, RATES & OTHER CHARGES   
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User agrees to pay other fees and charges and applicable taxes, related to the trilLOAN service and its use, as may be imposed by trilLOAN Inc., such as, but not limited to, ATM withdrawal, Bills Payment, remittance and encashment fees. Fees and other charges, as may be applicable, shall be inclusive of all applicable Philippine taxes and shall be debited from the Account Holder’s trilLOAN Account or paid up front. Should these fees and other charges result in a debit balance in the Account Holder’s trilLOAN Account, the amount shall be due and demandable from the trilLOAN User and/or debited from the trilLOAN account upon the availability or replenishment of funds thereof without prior notice. The amount of fees and charges may be revised from time to time as trilLOAN Inc. may deem necessary.   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    Standard carrier fees may be charged by Account Holder’s telecommunications service provider against Account Holder’s prepaid phone balance or post-paid phone account when using the trilLOAN Account for various transactions, such as but not limited to balance inquiry, over-the-air loading, ‘remote/off-site’ purchases, Account-to-Account transfer.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    VIII. TRANSACTION HISTORY    
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User at any time may view his/her transactions by logging-in to the trilLOAN Mobile App and transaction history made available for made transactions. The mere act of trilLOAN Inc. in making the Transactions history viewable at any time via trilLOAN App is conclusive presumption that the trilLOAN User has received the same. trilLOAN Inc. shall be held free and harmless from any and all liability should the Transactions be read by a person other than the user. Neither may trilLOAN In all instances, the trilLOAN User may inquire about the Statement of Transactions by contacting the trilLOAN Customer Support.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    IX. TRILLOAN USER RESPONSIBILITIES     
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    To protect trilLOAN User from any adverse incidents, trilLOAN User must:   
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    I. When the OTP and PIN notification is received, user can either change and memorize both for security protection  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    II. Make sure your PIN is not seen/read by any other person when being used in the presence of others; 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    III. Change your PIN regularly; 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    IV. If the PIN is or may have been seen by any other person, change it immediately 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    V. Do not write down or keep PIN on. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    VI. Do not allow any other person to use the PIN;  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN User shall be responsible in supplying the correct, updated and accurate information in order for trilLOAN Inc. to process the transaction. trilLOAN shall solely depend on the information given by the users, any loss or damage suffered by the error, mistake or incorrect debit/credit in the transaction, as a result of the information given, shall be borne by the trilLOAN User. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    All transactions made using the Service are conclusively presumed made by the trilLOAN User and the trilLOAN User shall be responsible therefore. The trilLOAN User has the option to change his/her PIN/password from time to time or when he suspects the possibility of unauthorized present or future use of the account.   
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    X. LOSS OF PHONE      
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    In case the trilLOAN User does not have an accessed with trilLOAN App, the trilLOAN User shall immediately inform trilLOAN Inc. through the trilLOAN Customer support of such loss within twenty-four (24) hours, via telephone or thru email. Cancellation of the trilLOAN Service shall be processed only upon proper authentication of the phone call or e-mail, as the case may be. All purchases and transactions made thru the use of the service prior to the report of loss shall continue to be the liability of the trilLOAN user.    
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The remaining Peso Value in the account in reference to the trilLOAN user number shall remain in good standing once the user log-in using the new reference mobile number of his account.     
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    XI. EXPIRY, RENEWAL, REINSTATEMENT, CANCELLATION OF ACCOUNT       
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User shall not use the trilLOAN Account after the expiry date indicated thereon. Renewal of the trilLOAN Account shall be subject to the approval of trilLOAN, Inc. trilLOAN Account which has been suspended by trilLOAN Inc. may be reinstated by trilLOAN at its option. The trilLOAN user continued use upon suspension; termination or expiration shall be considered a fraudulent act and will be a ground for criminal action. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    In case of dormancy, trilLOAN shall have the discretion to close or purge the account in accordance with the applicable law, rules and regulations.  
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    XII. TERMINATION OF TRILLOAN SERVICE        
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Should the trilLOAN User(a.) fail to comply with the Terms & Conditions provided herein or for any reason fail to renew the trilLOAN Account or (b.) the trilLOAN Account is not renewed by trilLOAN Inc. (c.) the account contains suspicious/fraudulent activity or (d.) trilLOAN User dies or becomes insolvent, however evidenced; the right to use the trilLOAN account shall be terminated without prior notice.   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    In all instances, any aggregate and unpaid charges, fees and other expenses for which the trilLOAN User is liable shall immediately become due without need of demand and may be immediately debited without prior notice from any remaining funds. 
                    </span>
                </div>    

                <div className={style.layeronetitle2}>
                    <span>
                    XIII. CHANGE OF TELEPHONE NUMBERS / ADDRESS         
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User shall immediately notify trilLOAN Customer Support via telephone or e-mail notice of any changes in his/her residence, office or mailing address and/or telephone number/s.  
                    </span>
                </div>  

                 <div className={style.layeronetitle2}>
                    <span>
                    XIV. CHANGE OF NAME         
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User shall immediately notify the Service Hotline via e-mail of any change in name of the Account Holder. The trilLOAN User shall likewise attach certified true copies of the necessary documents, such as but not limited to marriage certificate, or court order, as proof or evidence of such change.  
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    XV. EXCLUSION FROM LIABILITY          
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. makes no warranty, express or implied, regarding the performance or functionalities of the Service offered hereunder.   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The Service is offered on an “AS IS”, “AS AVAILABLE” basis without warranties of any kind, other than warranties that are incapable of exclusion, waiver or limitation under the laws applicable to this terms. Without limiting the generality of the foregoing, trilLOAN Inc. makes no warranty (1) as to the content, quality or accuracy of data or information provided by trilLOAN Inc. hereunder or received or transmitted using the trilLOAN service functionalities; (2) as to any service or product obtained using the Service functionalities; (3) that the Service will be uninterrupted or error-free; or (4) that any particular result or information will be obtained.  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. shall not be liable for any loss, costs, compensation, damage or liability to the trilLOAN User or third party arising directly or indirectly as a result of any or all of the following:  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.1 Refusal of trilLOAN Inc. or any other bank, financial institution, ATM or Merchant and the like to allow, accept or honor the trilLOAN account. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.2 The Service is honored by any other bank, financial institution, ATM or Merchant; however payment transaction is not authorized, for any reason whatsoever.  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                   17.3 Adjustments or reversals caused by incorrect entries, a missed or misdirected credit to the trilLOAN Account.  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                  17.4 trilLOAN User is unable to perform or complete any transaction through the use of Mobile Phone or Service online payments due to service/system/line unavailability. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.5 Any delay, interruption or termination of the Service transaction whether caused by administrative error, technical, mechanical, electrical or electronic fault or difficulty or any other reason or circumstance beyond trilLOAN Inc.’s control including but not limited to acts of God, strike, labor disputes, fire, disturbance, action of government, atmospheric conditions, lightning, interference or damage by third parties or any change in legislation. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.6 Theft or unauthorized use of the trilLOAN account or any loss, costs, damages or payable to any third party by the Account Holder. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.7 Any misrepresentation or fraud by or misconduct of any third party, such as but not limited to owners, employees or agents of Top-Up Centers. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.8 A trilLOAN Users shall not hold trilLOAN Inc. responsible or liable for any loss or damage which he may incur or suffer directly or indirectly arising out of or in connection with the Services due to any reason whatsoever including but not limited to breakdown or malfunction of the computer, its terminal connection lines, data processing system or transmission line whether or not belonging to trilLOAN Inc. or any circumstances beyond trilLOAN Inc.’s control. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    17.9 In the event of any unauthorized use of the Service, trilLOAN Inc. shall not be liable for any inconvenience or damage caused to the trilLOAN User or any third person. Moreover, trilLOAN Inc. shall not be liable for any failure by the trilLOAN User to avail itself of the Service for any reason whatsoever. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    18.0 trilLOAN Inc. may limit the number of transactions which may be approved in one (1) day. If trilLOAN Inc. detects any unusual or suspicious activity, trilLOAN Inc. may require the trilLOAN User to contact the Customer Support or temporarily suspend the Account Holder’s privileges until trilLOAN Inc. can verify the activity. 
                    </span>
                </div>  

                  <div className={style.layeronetitle3}>
                    <span>
                    18.1 trilLOAN User authorizes trilLOAN Inc. and/or any of its Agents to disclose to third parties any and all information trilLOAN User has provided and consent to the use and processing of such information by trilLOAN Inc. and or any of its agents or third parties with the transaction or any investigation in relation thereto. 
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    XVI. DATA PRIVACY NOTICE        
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    As a condition for use of the service, trilLOAN Users permit trilLOAN and its subsidiaries, affiliates, and authorized subcontractors to process and/or disclose personal data, including data that may be classified as personal information and/or sensitive personal information under the Data Privacy Act of 2012, to authorized agents, subsidiaries, affiliates, partners, and other authorized third parties, in order to undertake activities that may include, among others: 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    1. Conducting analysis for purposes of research and marketing initiatives, including the creation of your personal profile based on your interests, preferences, mobility patterns from physical locations that you may visit, and other information that may be relevant for marketing and market research purposes; 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    2. Managing your account, providing customer care activities, monitoring the quality and security of the network, training our staff, and providing the services to you in a timely and efficient manner;  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    3. Generating statistical insights based on your usage and other information to assist the Government in planning for healthcare, disaster, and other similar initiatives; 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    4. Credit scoring programs and initiatives, including but not limited to providing information to the Credit Information Corporation in furtherance of the objectives of Republic Act No. 9501, otherwise known as the Credit Information System Act; 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    5. Sending commercial and promotional advertisements, loyalty and rewards offers, surveys, customer-care, and after sales communications, and other broadcast push messages;  
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    6. Sharing your personal data with trilLOAN’s business partners, authorized third-party content providers or other authorized third-party services; and 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    7. Such other processing or disclosure that may be required under law or regulations. 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    Your personal data will be maintained in our records throughout your availment of the service, and for a maximum period of five years thereafter. You are afforded certain rights in relation to your personal data under the Data Privacy Act, including the right to object to processing, the right to access your data, the right to rectification of inaccurate data, and the right to erasure or blocking of data.  
                    </span>
                </div>  

                <div className={style.layeronetitle2}>
                    <span>
                    XVII. 19. DISCLOSURE         
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. shall keep all trilLOAN User files in strictest confidence. By supplying its personal information for the purpose of availing itself of trilLOAN Account, trilLOAN User expressly consents to the processing of its supplied personal information for the purpose of creating and maintaining such account. Such consent includes authorization for trilLOAN to disclose, exchange, and release the said information to its associates, affiliates, subsidiaries, officers, employees, agents, lawyers and other consultants, pre-paid/debit/credit bureaus or any such persons as trilLOAN Inc. deems necessary, or as required by law, rule or regulation. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XVIII. GOVERNING LAW         
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    This terms and condition shall be construed and governed in accordance with the laws of the Philippines. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XIX. VENUE OF LITIGATION         
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    Venue of all suits shall exclusively be at Calamba City, Laguna to the exclusion of all other venues. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XX. NON-WAIVER OF RIGHTS BY TRILLOAN        
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    Failure, omission, or delay on the part of trilLOAN Inc. to exercise its right or remedies under these Terms and Conditions shall not operate as a waiver. Any such waiver shall be valid only when reduced in writing and delivered to the trilLOAN User.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXI. SEPARABILITY CLAUSE        
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    Should any term or condition in this Agreement be rendered void, illegal or unenforceable in any respect under any law, the validity, legality and enforceability of the remaining terms and conditions shall not be affected or impaired thereby.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXII. AMENDMENTS         
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN Inc. may at any time and for whatever reason it may deem proper, amend, revise or modify these Terms and Conditions without further notice. It is the trilLOAN Account user’s responsibility to regularly check any changes to these Terms and Conditions at www.paychat.ph. The trilLOAN Account user’s continued use of the trilLOAN service after any such changes constitutes acceptance of the new Terms and Conditions. Failure to notify trilLOAN Inc. of trilLOAN user’s intention to terminate his/her account shall be construed as acceptance by the trilLOAN User of the amendments to these Terms and Conditions.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXIII. AGREEMENT     
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    The trilLOAN User agrees to be bound by the Terms and Conditions, Terms of Service and the Privacy Policy governing the use of the trilLOAN service. Should the trilLOAN User disagree with the Terms and Conditions, Terms of Service and the Privacy Policy the trilLOAN User shall delete the trilLOAN Application from his/her device (if applicable), and provide a written notice via email of cancellation to trilLOAN Customer support indicated below, otherwise, the trilLOAN User shall continue to be liable for all charges incurred through the use of the trilLOAN service.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXIV. COMPLAINTS HANDLING PROCEDURE      
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    Our customer's concerns, needs and feedbacks are very important. If you have any concerns about a procedure or have encountered a problem with our service, you may contact our Customer Support team:   
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    “trilLOAN Inc. aim to provide a high quality of service at all times. If you would like to discuss with us how our service could be improved or if you are dissatisfied with the service that you are receiving please let us know by contacting Customer Support @ hello@paychat.ph . “  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    You have our assurance that we will handle your concerns with utmost confidentiality and will strive to resolve them as soon as possible.  
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXV. TERMS OF SERVICE    
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. “Terms” used in this document pertains to the whole of this “Terms and Conditions of Use” including its subsequent amendments or revisions. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. “Services” means both mobile applications, channels and services, including all associated platforms linked therefrom, trilLOAN Inc., server and database, as well as all material, information, content, application and functionality available in and through them. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    3. “We” refers to the vendors and providers of the Services, including but not limited to trilLOAN Inc. its directors, officers, employees, agents, and/or assigns and successors, as well as the owners/operators, affiliates, and/or licensors of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    4. “You” refers to you as a user of the Services, and includes your agents, assigns, or successors, as well as your parents or legal guardians, as the case may be, for those below the age of majority. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    5. “User content” refers to any content generated or uploaded by a user of the Services, which includes photographs, videos, music or other multimedia files, documents, information, or anything else that can be stored electronically. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXVI. USER REPRESENTATION AND WARRANTIES    
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. By visiting and/or using the Services, you signify your agreement to these Terms and you further agree, affirm and warrant that there is and there shall be no agency, partnership, joint venture, employer-employee, licensor-licensee or franchisor-franchisee relationship between trilLOAN Inc. and you. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. You must be more than 18 years of age, or that you possess legal parental or guardian consent (for ages 12-17), and that you are fully able and competent to legally bind yourself to and abide by all of the terms, conditions, obligations, declarations, affirmations, representations, and warranties set forth in these Terms.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    3. By visiting and/or using the Services, you declare, represent and warrant that you understand that when using it, you will be exposed to content, material or information from a variety of sources, and that trilLOAN Inc. is not responsible for the accuracy, usefulness, safety, completeness, or intellectual property rights of or relating to such content, material or information, user generated, submitted or otherwise, including those content, material or information that may be linked to it. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                  4. You further understand and acknowledge trilLOAN Inc. may have or may develop and implement additional features and tie-ups with other entities. By virtue of such, you may be exposed to content that may infringe on your intellectual property rights or that of your principal; or content that may be violative of your privacy, and/or publicity rights; or content that is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, profane, threatening, racist, gruesome, objectionable, offensive to public morals, invasive of another’s privacy, or otherwise violative of any rule, regulation, or law; or content that contains any viruses, Trojan horses, worms, time bombs, bots, any malware, or any computer program, code, or routine that may or is intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information and that these you undertake at your own risk, and you hereby agree that trilLOAN Inc. shall not be responsible, liable, or accountable for any of the foregoing. You also agree to indemnify and hold trilLOAN Inc. harmless to the fullest extent allowed by law regarding all matters related to or arising out of your use of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    5. By visiting and/or using the Services, you declare, undertake and affirm that you take sole responsibility for whatever consequences that may arise out of your visit and/or use of the Services. You understand that trilLOAN Inc. shall not be liable for, and you hereby hold trilLOAN Inc. free from, any direct, incidental, special, consequential, indirect, or punitive damages whatsoever resulting from your use of, or your inability to use, the Services. On the other hand, you declare, undertake and affirm that you shall indemnify trilLOAN Inc. for any direct, incidental, special, consequential, indirect, or punitive damages whatsoever resulting from your use of the Services. You further agree, undertake, and commit to indemnify trilLOAN Inc. for any breach of its proprietary and other rights, including breach of these Terms, which you may commit in the course of or arising out of your use of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    6. You further declare, represent and warrant that you are aware that any content, material or information presented to you as part of the Services, including but not limited to advertisements and sponsored content, material or information within the Services may be protected by intellectual property rights which are owned by the respective sponsors, advertisers, or other persons or companies on their behalf. You agree that such advertisements and sponsored content, material or information may be placed on the Services and/or coupled to your User Content without prior notice and without need of explicit consent from you anymore as you now give such consent. You hereby declare, acknowledge, and affirm personal and sole commitment and obligation to respect uphold and honor any intellectual property rights which may cover such any content, material or information presented to you as part of the Services, including but not limited to advertisements and sponsored content, material or information within the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    7. You acknowledge that any material, information or content which you create, upload, transmit, or display while using the Services may become publicly available, whether or not intended by you or by trilLOAN Inc. for such material, information or content to be made publicly available. You further acknowledge, affirm and take sole responsibility for any consequences arising out of the use, authorized, lawful or otherwise, of any material, information or content which you create, upload, transmit or display using the Services. By visiting and/or using the Services, you hereby declare, acknowledge, and affirm to hold trilLOAN Inc. free from any liability by law or by equity arising therefrom, including the unauthorized or unlawful use thereof by a third party, regardless of negligence, lack of foresight, or lack of skill on the part of trilLOAN Inc., Inc. which may be directly or indirectly contributory to such unauthorized or unlawful use. 
                    </span>
                </div> 

                 <div className={style.layeronetitle3}>
                    <span>
                    8. You also acknowledge that you are free to opt out of the Services by voluntarily desisting from further use of the Services or by serving a notice of termination of use to trilLOAN Inc. and closing your account. Closure of an account with the Services does not nullify, void, cancel, or otherwise adversely affect any and/or all of the legal obligations and liabilities you may have incurred or may still incur relative to your use of the Services, as such termination of use of the Services and/or closure of an account with the Services does not nullify, void, cancel, or otherwise adversely affect any and/or all of the rights which may have accrued in favor of trilLOAN Inc. including the rights, consents, permissions and licenses to use any and/or all User Content that you have created, uploaded, transmitted, or displayed using the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    XXVII. ACCEPTABLE USE POLICY   
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    YOU AND THE SERVICES    
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. You agree not to incorporate any word in your name, message identification, or custom user title that is defamatory, obscene or profane, or which violates any trademark, service mark, or other intellectual property rights of any third party, including that of trilLOAN Inc. You likewise agree not to use any trademark, trade name, service mark, or logo in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names or logo. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. You agree not to alter, modify, or cause the alteration or modification, of the Services, without prior written consent from trilLOAN Inc. You further agree not to use the Services for any commercial use, without prior written authority from trilLOAN Inc. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    3. You agree not to use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any part of the Services, or in any way reproduce or circumvent its navigational structure or presentation, as well as to obtain or attempt to obtain any material, document or information through any means not purposely made available through the Services.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    4. You agree not to gain or attempt to gain unauthorized access to any part or feature of the Services or to any other system or network connected to the Services. You also agree not to gather, harvest, or otherwise collect information about others using the Services without their explicit informed consent; nor restrict, prevent or prohibit any other party from using the Services, including but not limited to such actions which may tend to discourage others from using the Services, such as stalking, flaming or the lashing out at other parties, spamming or the sending of unsolicited information, advertisement or content, flooding or the sending of repetitive message, trolling or the use of insulting or deliberately divisive information, material or content, other forms of annoyances, and the like. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    5. You agree not to circumvent, disable, or otherwise interfere with security-related features of the Services, including those that prevent or restrict use or copying of any content, material or information available on or through the Services, as well as those that enforce limitations on the use of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    6. You agree not to probe, scan or test the vulnerability of the Services or any network connected to it, and not to breach the security or authentication measures on the same. You agree not to reverse look-up, trace or seek to trace any information on any user of or visitor to the Services, or any other customer of trilLOAN Inc. including any account maintained with the Services not owned by you, to its source, or exploit the Services or any information made available or offered by or through the Services, in any way where the purpose is to reveal any information, including but not limited to personal identification, other than your own information. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    7. You agree to use or access the Services for your information and personal use solely as intended through the provided functionality of the Services. You agree not to copy or download any material or content from or through the Services unless such copying or downloading is explicitly allowed by a visible manifestation thereof such as a “download” button or a similar link ostensibly displayed. You further agree not to engage or attempt to engage in the use, copying, transmission, broadcast, display, distribution or sale of any of the contents, material or information available on or through the Services, including user comments and the like, other than as expressly permitted herein, or as explicitly indicated in the Services, including use thereof for commercial purposes. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    8. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Services or its systems or networks, or any systems or networks connected to the Services.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    9. You agree not to use any device or routine to interfere or attempt to interfere with the proper working of the Services or any transaction being conducted using the Services, or with any other person’s use of the Services. You agree that you will not engage in any activity that interferes with or disrupts the Services or the servers and networks which are connected to the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    10. You agree not to use the Services for any purpose that is illegal, unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes on the rights of trilLOAN Inc. or others. You further agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on any content, material or information, either in whole or in part, available on or through the Services, unless you have been specifically permitted to do so by trilLOAN Inc. or by the owner of that content, material or information in a separate agreement. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    YOU AND USER CONTENT    
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. You agree to and hereby undertake the sole responsibility, and hold trilLOAN Inc. free of liability to you or to any third party, for any User Content that you create, upload, transmit, or display while using the Services. You agree and hereby undertake sole responsibility for the consequences of your actions and interactions using the Services, and hereby stipulate admission of liability to trilLOAN Inc. for whatever loss or damage trilLOAN Inc. may suffer as a consequence of your use or misuse of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. You agree not to share, create, upload, transmit or display using the Services any material, information, or User Content which is or may be covered by copyright, patent, trade secret, trademark, trade name, service mark or any property rights, including privacy and/or publicity rights, unless you have the necessary licenses, rights, consents, and permissions to use and to authorize trilLOAN Inc. to use any and/or all User Content that you create, upload, transmit, or display using the Services.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                   3. You agree not to use a fictitious name or conceal your true legal name for the purpose of concealing crime, evading the execution of a judgment, or causing damage or prejudice to trilLOAN Inc., or other trilLOAN Users.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    4. You agree not to share, create, upload, transmit or display using the Services any material, information, or User Content which: infringes on the intellectual property, privacy, and/or publicity rights of any third party; is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, profane, threatening, racist, gruesome, offensive to public morals, invasive of another’s privacy, or otherwise violative of any rule, regulation, or law; contains any viruses, Trojan horses, worms, time bombs, bots, any malware, or any computer program, code, or routine that may or is intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information; and/or that which promotes or abets piracy. You further agree not to engage in pyramid, ponzi or similar schemes or other damaging schemes in the future; not to start or forward chain letters; and not to conduct or aid in the conduct of surveys, contests, petitions, and the like, using the Services, unless the Services has been specifically designed and explicitly made available for such purpose. 
                    </span>
                </div> 

                
                <div className={style.layeronetitle3}>
                    <span>
                    5. You represent, warrant and affirm that you own or that you have the necessary licenses, rights, consents, and permissions to use and to authorize trilLOAN Inc. to use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, or transmit over public networks and in various media any and/or all User Content that you create, upload, transmit, or display using the Services, as you hereby authorize and grant trilLOAN Inc. perpetual, irrevocable, worldwide, royalty-free and non-exclusive right, license, consent and permission to use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, or transmit over public networks and in various media any and/or all User Content which you may create, upload, transmit, or display using the Services, in a manner trilLOAN Inc. may deem fit and necessary, including but not limited to making such User Content available to a third party in compliance with a requirement of law, a rule or regulation, or making such User Content available to any third party for its use, reproduction, adaptation, modification, translation, publication, public performance, public display, distribution or transmission. You likewise hereby irrevocably agree, affirm and warrant to hold trilLOAN Inc. free from any liability, both under equity and the law, arising or that may arise out of its use of your User Content or the use of your User Content by a third party to which trilLOAN Inc. made available your User Content. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    6. Unless otherwise agreed in writing with trilLOAN Inc. you hereby agree to and acknowledge sole responsibility for protecting and enforcing any proprietary rights you or your principal may have on any User Content made available through the Services. You further acknowledge, agree, and admit that trilLOAN Inc. is not responsible for the actions of other users or any third party and hereby free trilLOAN Inc. and waive in its favor any or whatever right or claim you or your principal may have against trilLOAN Inc. relative to any User Content which you or a third party may create, upload, transmit or display using the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    7. You agree to store your password on the device and our servers in an encrypted form. This form of encryption disguises your password on the server, but still allows us to authenticate you when you sign into the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    8. You agree that by supplying its personal information for the purpose of availing yourself of the Service, you expressly consent to the processing of its supplied personal information for the purpose of creating and maintaining your account. Such consent includes authorization for trilLOAN Inc. to disclose, exchange, and release the said information to its associates, affiliates, subsidiaries, officers, employees, agents, lawyers and other consultants, pre-paid/debit/credit bureaus or any such persons as trilLOAN Inc. deems necessary, or as required by law, rule or regulation. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    THIRD PARTY CONTENT & SERVICES  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. The Services contains content provided by third party services and resources. You acknowledge and agree that we are not responsible or liable for: the availability or accuracy, appropriateness, completeness or non-infringement of such Third Party Services; or the content, products, or services available on or through such Third Party Services. The availability of such Third Party services does not imply any endorsement by us of such Third Party services or the content, products, or services available therefrom. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. You may not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the application and the services or in any Third Party Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    3. trilLOAN Inc. makes no representation or warranties whatsoever about any service, content and / or any other resources provided by the Third Party. 
                    </span>
                </div> 

                <div className={style.layeronetitle2}>
                    <span>
                    RESERVED RIGHTS   
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    1. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to deny access to and / or discontinue the Services or any component thereof to anyone at any time, temporarily or permanently, without prior notice. You hereby irrevocably agree, affirm and warrant to hold trilLOAN Inc. free from any liability, both under equity and the law, arising or that may arise out of any such denial of access to or the discontinuance of the Services.  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    2. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to collect, screen, review, flag, filter, modify, block, refuse or remove any and/or all information provided by any user, explicitly or implicitly to and through the Services, including but not limited to hardware information, IP address, browser-type related information, cookies and the like. You hereby irrevocably agree, affirm and warrant to hold trilLOAN Inc. free from any liability, both under equity and the law, arising or that may arise out of any such collection, screening, review, flagging, filtering, modification, blocking, refusal or removal of any and/or all information provided by any user to and through the Services.   
                    </span>
                </div> 

                
                <div className={style.layeronetitle3}>
                    <span>
                    3. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to enhance, improve, develop and introduce new features and functionalities to the Services at any time and without prior notice. You hereby understand, agree, and affirm that any such enhancement, improvement, development, new feature and/or new functionality to the Services shall form part of the Services as defined herein and thus shall likewise be covered by these Terms and its subsequent revisions or amendments, as applicable. 
                    </span>
                </div> 

                
                <div className={style.layeronetitle3}>
                    <span>
                    4. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion, to verify, check, cross-refer, validate, and ascertain the veracity and truthfulness of all information supplied by you by acquiring, accessing, retrieving, or otherwise acquiring similar or additional information supplied by you to other third-party service providers, including, but not limited to telecommunications providers, etc. You hereby expressly, unequivocally, and voluntarily allow trilLOAN Inc. to request for and secure such information, and expressly, unequivocally, and voluntarily instruct such third-party providers to: (a) receive and process trilLOAN request; (b) favourably act at all times on any such request by producing the information requested; and (c) when requested by trilLOAN Inc. provide the latter with certified digital or printed copies of the said information. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    5. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to send you or cause to send you service updates and/or messages, including SMS, notifications, email and/or any data message transmission, informing you of enhancements, improvements, developments, features, functionalities, products, promotions, offers, advertisement and/or any other information relative to the Services and trilLOAN Inc.. trilLOAN Inc. makes no warranty of any kind, express or implied, for such service updates and/or messages, but you hereby agree to receive such service updates and/or messages and hold trilLOAN Inc. free from any liability and/or claims for indemnification or damages that may arise therefrom. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    6. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to set limitations to and charge fees and applicable taxes for the use of the Services, at any time and without prior notice. You further understand and agree that trilLOAN Inc. reserves the right, at its sole discretion and under no obligation, to change the applicable fees, taxes and charges levied for the use of the Services, at any time and with prior written notice to you sixty (60) days prior to effective date of change in fees / charges /penalties. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    7. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to change, modify or amend these Terms without prior notice. You hereby understand, agree, and affirm that it shall be your sole responsibility to be aware of any such change, modification or amendment, which shall take effect immediately upon publication in the website of trilLOAN Inc., or upon its availability through the Services and which shall bind you as soon as you use or access the Services regardless of whether or not you are already aware of such change, modification or amendment. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                  8. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to enforce the provisions of these Terms. Non-enforcement of any of the rights of trilLOAN Inc. under these Terms, under the law or under principles of equity shall not be construed as a waiver thereof. Likewise, no subsequent course of action by trilLOAN Inc. by you, and/or by any third party, individually or collectively, shall be construed or operate as an abandonment, amendment, or modification of these Terms. You likewise hereby declare, affirm and undertake the sole obligation to indemnify trilLOAN, Inc., Inc. or any third party for any damage trilLOAN Inc., Inc. or said third party may sustain as a result of your use of the Services. 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    9. You understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to limit the provision, availability, quantity and quality of any feature, product or service to any person or to anyone within the same geographic area, demographic profile, or any other market, commercial, and/or trading segments. You likewise understand and agree that trilLOAN Inc. reserves the right, at its sole discretion but without obligation, to administer and operate any and/or all of the Services from any or various locations outside the Republic of the Philippines. You further understand, agree and hold trilLOAN Inc. free from any liability arising therefrom, that not all features, products, or services discussed, referenced, provided or offered through or in the Services are available to all persons or in all geographic locations, or are appropriate or available for use outside the Republic of the Philippines. Any part or the whole of the Services or these Terms are void where prohibited. You hereby understand, agree, and undertake sole responsibility for your continued access to or use of the Services, as well as the results or consequences of such access and use, including the responsibility for compliance with applicable local laws and the sole liability for non-compliance or breach thereof. 
                    </span>
                </div> 
                </div> 
        </div>
    )

}
export default terms;
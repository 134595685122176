import React, { useState } from "react";
import First from "../components/firstLayer";
import Second from "../components/secondLayer";
import Third from "../components/thirdLayer";
import Fourth from "../components/fourthLayer";
import Fifth from "../components/fifthLayer";
import Sixth from "../components/sixthLayer";
import Seventh from "../components/seventhLayer";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Element } from "react-scroll"; // Import Element component
import LinkPreview from "../components/LinkPreview";
export default function Home() {
  const [linkData, setLinkData] = useState({
    url: "https://official.paychat.ph/",
    title: "trilLOAN",
    description: "Chat and Transact in one place!",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYUIJvrWEv0f2n_8lj-x7k0bQE7onBO09fuQ&s",
  });

  return (
    <>
      <div className="App">
        <Element className="section9">
          <Navbar />
        </Element>
        <Element className="section1">
          <First />
        </Element>
        <Element name="section2">
          <Second />
        </Element>
        <Element name="section3">
          <Third />
        </Element>
        <Element name="section4">
          <Fourth id="fourth-section" />
        </Element>
        <Element name="section5">
          <Fifth id="fifth-section" />
        </Element>
        <Element name="section6">
          <Sixth id="sixth-section" />
        </Element>
        <section>
          <Element name="section7">
            <Seventh id="seventh-section" />
          </Element>
        </section>
        <Element className="section8">
          <Footer />
        </Element>
      </div>
      <LinkPreview {...linkData} />
    </>
  );
}

import backgroundImg  from '../assets/background/img_download_bg2.png'
import appStore from '../assets/background/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import playStore from '../assets/background/google-play-badge.svg'
import handWithCellphone from '../assets/first/footer_image.png'
import fakeQr from '../assets/background/fake_qr.png'

function SixthLayer() {

    return (
    <div className="seventhLayer" style={{ background: `url(${backgroundImg})`, backgroundSize: 'cover' }}>
         <div className='mobile2'>
         <div className="seventhLayerOne">
            <p>Get trilLOAN on your phone,</p>
            <p>Scan to Download now!</p>
         </div>
         <div className="seventhLayerQR">
           <img src={fakeQr} alt='placeholder for qr'/>
           </div>
         <div className="seventhLayerTwo">
         <div className="seventhLayerThree">
           <img src={appStore} alt='AppleStore'/>
           </div>
           <div className="seventhLayerFour">
           <img src={playStore} alt='Andriod Play Store'/>
         </div>
         </div>
         <div className="seventhLayerFive">
            <p>For Inquiries, email us at</p>
            <span>support@paychat.ph</span>
         </div>
         <div className="seventhLayerSix">
            <img src={handWithCellphone}  alt='Hand with a cellphone again'/>
         </div>
         </div>
         <div className='tablet2'>
         <div className='handCellphone'>
            <img src={handWithCellphone}  alt='Hand with cellphone'/>
         </div>
         <div className="seventhLayerOne">
         <div>
            <p>Get trilLOAN on your phone,</p>
            <p>Scan to Download now!</p>
         </div>
         <div className="seventhLayerQR">
           <img src={fakeQr} alt='placeholder for qr'/>
           </div>
         <div className='storeApp'>
            <div className="seventhLayerThree">
               <img src={appStore}  alt='AppleStore'/>
            </div>
            <div className="seventhLayerFour">
               <img src={playStore}  alt='Andriod Play Store'/>
            </div>
         </div>
         <div className="seventhLayerFive">
            <p>For Inquiries, email us at</p>
            <span>support@paychat.ph</span>
         </div>
         </div>
         </div>

         <div className='webReso'>
         <div className='handCellphone'>
            <img src={handWithCellphone}  alt='Hand with cellphone'/>
         </div>
         <div className="seventhLayerOne">
         <div>
            <p>Get trilLOAN on your phone,</p>
            <p>Scan to Download now!</p>
         </div>
         <div className="seventhLayerThree">
               <img src={fakeQr}  alt='AppleStore'/>
            </div>
         <div className='storeApp'>
            <div className="seventhLayerThree">
               <img src={appStore}  alt='AppleStore'/>
            </div>
            <div className="seventhLayerFour">
               <img src={playStore}  alt='Andriod Play Store'/>
            </div>
         </div>
         <div className="seventhLayerFive">
            <p>For Inquiries, email us at</p>
            <span>support@paychat.ph</span>
         </div>
         </div>
         </div>
        
        </div>
    )
}
export default SixthLayer;
// import React, { useState, useEffect} from 'react';
import React, { useState} from 'react';
import chevron from '../assets/logo/ic_chev_down.svg'
import chevron2 from '../assets/logo/ic_chev_up.svg'
import style from './index.module.css'
import Navbar  from '../components/navbar';
import Footer from '../components/footer';
import { Element } from 'react-scroll'; // Import Element component

function FAQs () {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
    const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
    const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
    const [isDropdownOpen6, setIsDropdownOpen6] = useState(false);
    const [isDropdownOpen7, setIsDropdownOpen7] = useState(false);
    const [isDropdownOpen8, setIsDropdownOpen8] = useState(false);
    const [isDropdownOpen9, setIsDropdownOpen9] = useState(false);
    const [isDropdownOpen10, setIsDropdownOpen10] = useState(false);
    const [isDropdownOpen11, setIsDropdownOpen11] = useState(false);
    const [isDropdownOpen12, setIsDropdownOpen12] = useState(false);
    const [isDropdownOpen13, setIsDropdownOpen13] = useState(false);
    const [isDropdownOpen14, setIsDropdownOpen14] = useState(false);
    const [isDropdownOpen15, setIsDropdownOpen15] = useState(false);
    const [isDropdownOpen16, setIsDropdownOpen16] = useState(false);
    const [isDropdownOpen17, setIsDropdownOpen17] = useState(false);
    const [isDropdownOpen18, setIsDropdownOpen18] = useState(false);
    const [isDropdownOpen19, setIsDropdownOpen19] = useState(false);
    const [isDropdownOpen20, setIsDropdownOpen20] = useState(false);
    const [isDropdownOpen21, setIsDropdownOpen21] = useState(false);
    const [isDropdownOpen22, setIsDropdownOpen22] = useState(false);
    const [isDropdownOpen23, setIsDropdownOpen23] = useState(false);
    const [isDropdownOpen24, setIsDropdownOpen24] = useState(false);
    const [isDropdownOpen25, setIsDropdownOpen25] = useState(false);
    const [isDropdownOpen26, setIsDropdownOpen26] = useState(false);
    const [isDropdownOpen27, setIsDropdownOpen27] = useState(false);
    const [isDropdownOpen28, setIsDropdownOpen28] = useState(false);
    const [isDropdownOpen29, setIsDropdownOpen29] = useState(false);
    const [isDropdownOpen30, setIsDropdownOpen30] = useState(false);
    const [isDropdownOpen31, setIsDropdownOpen31] = useState(false);
    const [isDropdownOpen32, setIsDropdownOpen32] = useState(false);
        


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
    const toggleDropdown2 = () => {
        setIsDropdownOpen2(!isDropdownOpen2);
      };
      const toggleDropdown3 = () => {
        setIsDropdownOpen3(!isDropdownOpen3);
      };
      const toggleDropdown4 = () => {
        setIsDropdownOpen4(!isDropdownOpen4);
      };
      const toggleDropdown5 = () => {
        setIsDropdownOpen5(!isDropdownOpen5);
      };
      const toggleDropdown6 = () => {
        setIsDropdownOpen6(!isDropdownOpen6);
      };
      const toggleDropdown7 = () => {
        setIsDropdownOpen7(!isDropdownOpen7);
      };
      const toggleDropdown8 = () => {
        setIsDropdownOpen8(!isDropdownOpen8);
      };
      const toggleDropdown9 = () => {
        setIsDropdownOpen9(!isDropdownOpen9);
      };
      const toggleDropdown10 = () => {
        setIsDropdownOpen10(!isDropdownOpen10);
      };
      const toggleDropdown11 = () => {
        setIsDropdownOpen11(!isDropdownOpen11);
      };
      const toggleDropdown12 = () => {
        setIsDropdownOpen12(!isDropdownOpen12);
      };
      const toggleDropdown13 = () => {
        setIsDropdownOpen13(!isDropdownOpen13);
      };
      const toggleDropdown14 = () => {
        setIsDropdownOpen14(!isDropdownOpen14);
      };
      const toggleDropdown15 = () => {
        setIsDropdownOpen15(!isDropdownOpen15);
      };
      const toggleDropdown16 = () => {
        setIsDropdownOpen16(!isDropdownOpen16);
      };
      const toggleDropdown17 = () => {
        setIsDropdownOpen17(!isDropdownOpen17);
      };
      const toggleDropdown18 = () => {
        setIsDropdownOpen18(!isDropdownOpen18);
      };
      const toggleDropdown19 = () => {
        setIsDropdownOpen19(!isDropdownOpen19);
      };
      const toggleDropdown20 = () => {
        setIsDropdownOpen20(!isDropdownOpen20);
      };
      const toggleDropdown21 = () => {
        setIsDropdownOpen21(!isDropdownOpen21);
      };
      const toggleDropdown22 = () => {
        setIsDropdownOpen22(!isDropdownOpen22);
      };
      const toggleDropdown23 = () => {
        setIsDropdownOpen23(!isDropdownOpen23);
      };
      const toggleDropdown24 = () => {
        setIsDropdownOpen24(!isDropdownOpen24);
      };
      const toggleDropdown25 = () => {
        setIsDropdownOpen25(!isDropdownOpen25);
      };
      const toggleDropdown26 = () => {
        setIsDropdownOpen26(!isDropdownOpen26);
      };
      const toggleDropdown27 = () => {
        setIsDropdownOpen27(!isDropdownOpen27);
      };
      const toggleDropdown28 = () => {
        setIsDropdownOpen28(!isDropdownOpen28);
      };
      const toggleDropdown29 = () => {
        setIsDropdownOpen29(!isDropdownOpen29);
      };
      const toggleDropdown30 = () => {
        setIsDropdownOpen30(!isDropdownOpen30);
      };
      const toggleDropdown31 = () => {
        setIsDropdownOpen31(!isDropdownOpen31);
      };
      const toggleDropdown32 = () => {
        setIsDropdownOpen32(!isDropdownOpen32);
      };

//        const [showButton, setShowButton] = useState(false);
//        const [fixedButton, setFixedButton] = useState(false);

//        useEffect(() => {
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const handleScroll = () => {
//     const scrollY = window.pageYOffset;

//     if (scrollY > 200 &&scrollY <1550) {
//       setShowButton(true);
//     } else {
//       setShowButton(false);
//     }

//     if (scrollY > 1550) {
//       setFixedButton(true);
//     } else {
//       setFixedButton(false);
//     }
//   };

   

  

//   const scrollToTop = () => {
 
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//   };

     


    return (
        <>
        <Navbar />

      
       
        <div className={style.faqs}>

        {/* {showButton && (
        <div className={style.scrollbutton}>
        <button onClick={scrollToTop}>
          Scroll to Top
        </button>
        </div>
      )}
       {fixedButton && (
        <div className={style.scrollbuttonfixed}>
          <button onClick={scrollToTop}>Scroll to Top</button>
        </div>
      )} */}
           
         <div className={style.layeronediv}>
                <div className={style.layeronetitle}>
                    <p>
                       Frequently Asked
                    </p>
                    <p>
                       Questions
                    </p>
                </div>   
                </div>   
                <div className={style.layeronetitlewide}>
            <p>
            Frequently Asked  Questions
            </p>
            </div>
                
            <div className={style.contentdiv}>
                <div className={style.dropdown} onClick={toggleDropdown} >
                  <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown}>
                     What is trilLOAN?
                    </span>
                    </div> 
                    <div className={style.imagediv}> 
                    <img 
                     src={isDropdownOpen ? chevron2 : chevron} // Change 'active-chevron.png' to your desired active image path
                    alt="Chevron" 
                    className={style.chevronimage} />  
                    </div>
                    </div>
                   {isDropdownOpen && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >trilLOAN is a mobile payment platform intending to be a super app in the Philippines.</span>
                    </div>
                </div>
                )}
                    </div> 
                   
                    <div className={style.dropdown} onClick={toggleDropdown2} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Where can I download the trilLOAN app?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen2 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen2 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >You can download the trilLOAN App for <strong>free</strong> from the Apple App Store and Google Play Store.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown3} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Does trilLOAN have a wallet?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen3 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen3 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >trilLOAN does not have a digital wallet. trilLOAN users can link their Union Bank or BPI accounts for transactions such as sending and receiving money. Users can link any credit or debit card issued by banks and e-wallets with the Visa or Mastercard logo.</span>
                    </div>
                </div>
                )}
                    </div> 

                    
                    <div className={style.dropdown} onClick={toggleDropdown4} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Which devices are compatible to run the trilLOAN app?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen4 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage3} /> 
                    </div>
                    </div>
                    {isDropdownOpen4 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >trilLOAN can run on any android device running Android 6.0 (Marshmallow) and above, and iOS 12.10 and above for iPhones.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown5} >
                       <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I create a trilLOAN account?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen5 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen5 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                   
                    <span className={style.step}>Step 1: Download trilLOAN on Google Play Store (Android) or App Store (iOS)</span>
                    <span className={style.step}>Step 2: Enter Mobile Number</span>
                    <span className={style.step}>Step 3: Enter 6-digit OTP</span>
                    <span className={style.step}>Step 4: Enter Personal Information</span>
                    <span className={style.step}>Step 5: Set up your Password, MPIN and Security Questions</span>
                    <span className={style.step}>Step 6: Agree to Terms and Conditions</span>
                    <span className={style.step}>Step 7: Account creation completed</span>
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown6} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Which valid IDs do you accept to upgrade my account?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen6 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage3} /> 
                    </div>
                    </div>
                    {isDropdownOpen6 && (
    <div className={style.dropdownContent}>
        <div className={style.line}></div>
        <div className={style.descriptiondiv}>
            <div className="ids-container">
            <div className={style.step2}>
                <span className={style.step}>We accept the following as valid primary IDs:</span>
                    
                <span className={style.step3}>- Philippine Driver’s License</span>
                <span className={style.step3}>- Philippine Passport</span>
                <span className={style.step3}>- UMID</span>
                <span className={style.step3}>- SSS ID</span>
                <span className={style.step3}>- PRC ID</span>
                <span className={style.step3}>- Postal ID (issued 2015 onwards)</span>
                <span className={style.step3}>- Voter’s ID</span>
                <span className={style.step3}>- National ID/Physys ID</span>
                <span className={style.step3}>- Philhealth ID</span>
                </div>
                
                <div className={style.step2}>
                <span className={style.step}>In case you do not have a primary, we also accept the following as secondary IDs:</span>
                 
                <span className={style.step3}>- PSA Birth Certificate</span>
                <span className={style.step3}>- Solo Parent ID (DSWD)</span>
                <span className={style.step3}>- Seaman's Book (MARINA)</span>
                <span className={style.step3}>- DSWD Certification</span>
                <span className={style.step3}>- POEA Card</span>
                <span className={style.step3}>- Consular ID (Philippine Embassy)</span>
                <span className={style.step3}>- PRA Special Resident Retiree Visa</span>
            </div>
                   
                <em className={style.step}>
                    Note: You are required to submit one (1) PRIMARY ID when upgrading your account to Verified.
                    You can only use your one (1) SECONDARY ID to upgrade to Registered only.
                </em>
            </div>
        </div>
    </div>
)}

                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown7} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Why was my account upgrade rejected?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen7 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen7 && (
                <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    Listed below are possible reasons why your account upgrade was rejected:</span>
                        </div>
            <ul className="reasons-list" style={{ listStyleType: 'none', paddingInlineStart: '0' }}>
            <span className={style.step3}>- Your personal information such as your name or address does not match the details on the valid ID you uploaded.</span>
            <span className={style.step3}>- You uploaded an expired ID or it is not listed on our list of valid ID requirements.</span>
            <span className={style.step3}>- Your ID, image selfie, or video selfie is blurry or not clear.</span>
                <span className={style.step3}>- Your age does not meet the minimum requirement to upgrade for a Registered or Verified account.</span>
            </ul>
        </div>
    </div>
)}

                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown8} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     I lost my SIM and/or phone with the number that's registered to my trilLOAN account. What do I do?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen8 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage4} /> 
                    </div>
                    </div>
                    {isDropdownOpen8 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>

                    <span className={style.method2}>In case of lost/expired SIM or lost/stolen phone, you may request to change your registered mobile number or deactivate your account.</span>
                    <div className={style.step2}>
                    <span className={style.step}>
                        Kindly contact trilLOAN Support by:</span>
                        </div>
                    
                    <span className={style.step3}>- Submit a ticket on the app;</span>
                    <span className={style.step3}>- Message trilLOAN Support through Live Chat;</span>
                    <span className={style.step3}>- Send us an email to support@paychat.ph, or;</span>
                    <span className={style.step3}>- Call our landline at 02-7005-3458. </span>
                    </div>
                </div>
                )}
                    </div> 

                   <div className={style.dropdown} onClick={toggleDropdown9} >
                   <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I change my registered email address?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen9 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen9 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span className={style.step}>Step 1: Tap on your profile picture to open the side menu, and select <strong>Profile and Settings</strong>.</span>
                    <span className={style.step}>Step 2: In Profile and Settings, tap the <strong>pencil</strong> button on the right of your registered email address.</span>
                    <span className={style.step}>Step 3: Verify your identity by answering your security question.</span>
                    <span className={style.step}>Step 4: Tap <strong>Confirm</strong>.</span>
                    <span className={style.step}>Step 5: Enter the new email address that you want to use, then tap <strong>Submit</strong>.</span>
                    <span className={style.step}>Step 6: Kindly wait for your change e-mail request to be approved. If no changes have been made within 24 hours, contact trilLOAN Support for assistance.</span>
                  
                    </div>
                </div>
                )}
                    </div> 
                    <div className={style.dropdown} onClick={toggleDropdown10} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I change my password? / I forgot my password.   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen10 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen10 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span className={style.method}>
                    <div className={style.methoddiv}>
                    <span className={style.method3}>Method 1: If you can't log in to your account because you forgot your password:</span>
                    </div>
                    <span className={style.step3}>Step 1: Enter your mobile number to log in.</span> 
                    <span className={style.step3}>Step 2: Enter the OTP sent to your mobile number. <strong>Tap Verify</strong>.</span>
                    <span className={style.step3}>Step 3: Tap on <strong>Forgot Password</strong>.<br /></span>
                    <span className={style.step3}>Step 4: Enter your mobile number or email address registered to your trilLOAN account.<br /></span>
                    <span className={style.step3}>Step 5: Enter the OTP sent to your number or email.<br /></span>
                    <span className={style.step3}>Step 6: Answer your security question.<br /></span>
                    <span className={style.step3}>Step 7: Create your new password. Tap <strong>Confirm</strong>.<br /></span>
                    <span className={style.step3}>Step 8: Tap the <strong>Done</strong> button to log in with your new password.<br /></span>
                </span>
                <span className={style.method}>
                <div className={style.methoddiv}>
                    <span className={style.method3}>Method 2: If you are logged in to your account and want to update your password:</span>
                    </div>
                    <span className={style.step3}>Step 1: Tap on your <strong>Profile Picture</strong> to open the side menu.<br /></span>
                    <span className={style.step3}>Step 2: Tap on Profile and Settings, go to Settings, and tap on <strong>Password</strong> under Security.<br /></span>
                    <span className={style.step3}>Step 3: To update your password, enter your current password then create your new password. If you forgot your current password, proceed to Method 3.<br /></span>
                    <span className={style.step3}>Step 4: Answer your security question.<br /></span>
                    <span className={style.step3}>Step 5: Tap the <strong>Done</strong> button when finished.<br /></span>
                </span>
                <span className={style.method}>
                <div className={style.methoddiv}>
                    <span className={style.method3}>Method 3: If you forgot your password while you are logged in to your account, follow the steps in Method 2 and continue with the instructions below:</span>
                    </div>
                    <span className={style.step3}>Step 1: Enter your mobile number or email address registered to your trilLOAN account.<br /></span>
                    <span className={style.step3}>Step 2: Enter the OTP sent to your number or email.<br /></span>
                    <span className={style.step3}>Step 3: Answer your security question.<br /></span>
                    <span className={style.step3}>Step 4: Create your new password. Tap <strong>Confirm</strong> to save your new password.<br /></span>
                </span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown11} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I change my security questions? / I forgot my security questions.   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen11 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage3} /> 
                    </div>
                    </div>
                    {isDropdownOpen11 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    To update your security questions, do the following:</span>
                        </div>
                    <span className={style.step3}>Step 1: Tap on your profile picture and select <strong>Profile and Settings</strong>.</span>
                    <span className={style.step3}>Step 2: Tap on <strong>Settings</strong>.</span>
                    <span className={style.step3}>Step 3: Tap on <strong>Security Questions</strong>.</span>
                    <span className={style.step3}>Step 4: Enter the OTP sent to your mobile number.</span>
                    <span className={style.step3}>Step 5: On the Security Questions screen, tap on the <strong>Edit</strong> button.</span>
                    <span className={style.step3}>Step 6: Enter your trilLOAN account password.</span>
                    <span className={style.step3}>Step 7: Change your security questions.</span>
                    <span className={style.step3}>Step 8: Tap the Update button to save your changes.</span>
                    </div>
                </div>
                )}
                    </div> 

                     <div className={style.dropdown} onClick={toggleDropdown12} >
                     <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     What payment methods are available on the trilLOAN app?   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen12 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen12 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
               
                    <div className={style.step2}>
                    <span className={style.step}>
                    You can use the following as your payment method on trilLOAN:</span>
                        </div>
                
                    <span className={style.step3}>- BPI account</span>
                    <span className={style.step3}>- Union Bank account</span>
                    <span className={style.step3}>- Visa and Mastercard credit or debit card</span>
                    <span className={style.step3}>- BillEase account*</span>
                    <br />
                    <em>
                    *You can only use your BillEase account for Pay Bills and Send Gift transactions amounting to P3,000.00 or more.
                </em>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown13} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I link my Union Bank or BPI account?   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen13 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen13 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    Link your BPI or Union Bank account by:</span>
                        </div>
                    <span className={style.step3}>Step 1: You have to upgrade your account to Registered/ Verified for you to link your Union Bank or BPI account.</span>
                    <span className={style.step3}>Step 2: Tap on your profile picture to open the side menu, and select <strong>Payment Methods.</strong></span>
                    <span className={style.step3}>Step 3: Tap <strong>Add Linked Account</strong> under Linked Accounts. </span>
                    <span className={style.step3}>Step 4: Select the Bank that you want to add.</span>
                    <span className={style.step3}>Step 5: Enter your bank login/account details.</span>
                    <span className={style.step3}>Step 6: Tap <strong>Confirm</strong>.</span>
                    <span className={style.step3}>Step 7. Enter the One-Time-Password or OTP sent to your registered mobile number to validate the transaction.</span>
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown14} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I add my credit or debit card to trilLOAN?   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen14 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen14 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    Add your credit or debit card via checkout by send gift, pay bills, or buy load transactions:</span>
                        </div>
                    <span className={style.step3}>Step 1: On the Confirmation screen, scroll down and tap on <strong>Payment Methods</strong>. </span>
                    <span className={style.step3}>Step 2: Tap on <strong>Credit / Debit Card</strong> and you will be redirected to trilLOAN's partner card payments processor.</span>
                    <span className={style.step3}>Step 3: Enter your card details and make sure you tick on the checkbox <strong>"Save card for future payment."</strong></span>
                    <span className={style.step3}>Step 4: Tap on <strong>Continue Payment</strong> to proceed with the transaction.</span>
                    <span className={style.step3}>Step 5. Enter the One-Time-Password or OTP sent to your email address to validate the transaction.</span>
                    <span className={style.step3}>Step 6: Once the payment is successful, your card details will be saved to your Payment Methods. </span>
                 
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown15} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I use my BillEase account to pay bills or buy eGifts?   
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen15 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage3} />
                    </div> 
                    </div> 
                    {isDropdownOpen15 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    To pay with BillEase:</span>
                        </div>
                  
                    <span className={style.step3}>Step 1: On the Confirmation screen of Pay Bills or Send Gift, scroll down and tap on <strong>Payment Methods</strong>. </span>
                    <span className={style.step3}>Step 2: On the Payment Methods screen select <strong>Pay with BillEase</strong>.</span>
                    <span className={style.step3}>Step 3: You will be redirected to BillEase's online portal.</span>
                    <span className={style.step3}>Step 4: Sign in using your BillEase username and password.</span>
                    <span className={style.step3}>Step 5: Choose your terms.</span>
                    <span className={style.step3}>Step 6: Enter the OTP sent to your mobile number.</span>
                 
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown16} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Are my card details safe?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen16 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage} /> 
                    </div>
                    </div>
                    {isDropdownOpen16 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Yes. trilLOAN uses an accredited third-party card payments processor for credit or debit card transactions, therefore, we do not collect or store your card information. We assure you that your card details and other financial information is encrypted and secured.</span>
                    </div>
                </div>
                )}
                    </div> 

                   <div className={style.dropdown} onClick={toggleDropdown17} >
                   <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Are my transactions safe?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen17 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage} /> 
                    </div>
                    </div>
                    {isDropdownOpen17 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Yes, all your transactions on trilLOAN are encrypted and secured. Your transactions are also authenticated by entering a One-Time-Password or OTP sent to your registered mobile number. Never share your OTP to anyone to keep your account safe.</span>
                    </div>
                </div>
                )}
                    </div> 

                     <div className={style.dropdown} onClick={toggleDropdown18} >
                     <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     Where can I view my transactions?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen18 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen18 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Tap on your <strong>profile picture</strong> to open the side menu, and select <strong>Notifications</strong> which will direct you to Transactions tab where you can view all your transactions.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown19} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I send money?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen19 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage} />  
                    </div>
                    </div>
                     {isDropdownOpen19 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span className={style.step}>Step 1: On the Send/Borrow tab, select on where to send money. You can choose on whether to send money to Linked Account, Bank Account, Pay Out Center and Send to Many. </span>
                    <span className={style.step}>Step 2: On the next screen, choose <strong>Send</strong> in the Send/Request dropdown menu, then fill out Send Money form.</span>
                    <span className={style.step}>Step 3: Tap <strong>Next</strong>.</span>
                    <span className={style.step}>Step 4: On the Confirmation Screen, review your details then tap <strong>Send</strong>.</span>
                    <span className={style.step}>Step 5: Enter 6-digit One-Time-Password for verification.</span>
                    </div>
                </div>
                )}
                    </div> 
                    
                    <div className={style.dropdown} onClick={toggleDropdown20} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I pay bills?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen20 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage} /> 
                    </div>
                    </div>
                    {isDropdownOpen20 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span className={style.step}>Step 1: On the Pay Bills tab, select the biller you want to pay from. </span>
                    <span className={style.step}>Step 2: Fill in the billing details.</span>
                    <span className={style.step}>Step 3: Tap <strong>Next</strong>.</span>
                    <span className={style.step}>Step 4: On the Confirmation Screen, review your billing details then tap <strong>Pay</strong>.</span>
                    <span className={style.step}>Step 5: Enter 6-digit One-Time-Password for verification.</span>
                    </div>
                </div>
                )}
                    </div> 

                     <div className={style.dropdown} onClick={toggleDropdown21} >
                     <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     How do I buy load?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen21 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage} /> 
                    </div>
                    </div>
                    {isDropdownOpen21 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span className={style.step}>Step 1: On the Buy Load tab, select the Telco product you want to pay from. </span>
                    <span className={style.step}>Step 2: Fill in mobile number.</span>
                    <span className={style.step}>Step 3: Select the load product that you want to purchase whether it is a regular load or promo.</span>
                    <span className={style.step}>Step 4: Tap <strong>Next</strong>.</span>
                    <span className={style.step}>Step 5: On the Confirmation Screen, review your buy load details then tap <strong>Pay</strong>.</span>
                    <span className={style.step}>Step 6: Enter 6-digit One-Time-Password for verification.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown22} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     What is send to many? How does it work?  
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen22 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen22 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    You can send money to 2 - 5 people at once under one convenience fee. </span>
                        </div>
                    <span className={style.step3}>Step 1. On the Send/Borrow tab, tap on <strong>Send to Many</strong>.</span>
                    <span className={style.step3}>Step 2. Select at least 2 people from your contact list.</span>
                    <span className={style.step3}>Step 3. On the recipient list, select where you want to send your funds to that specific person. For non-trilLOAN users, you can enter their bank or remittance details. You can also add more recipients on this screen by selecting a contact or typing a mobile number.</span>
                    <span className={style.step3}>Step 4. Enter the amount you want to send to each recipient and select your payment method.</span>
                    <span className={style.step3}>Step 5. On the Confirmation screen, review your send money details and tap <strong>Send</strong>.</span>
                    <span className={style.step3}>Step 6. Enter the 6-digit OTP to authenticate the transaction.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown23} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >
                     What is send gift? How do I buy eGifts? 
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen23 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen23 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    trilLOAN's Send Gift feature allows user to purchase eGift vouchers for their loved ones. EGift vouchers are digital gift cards which allows user to make purchase without using cash. </span>
                        </div>
                    <span className={style['method-title']}>To buy/send eGifts: </span>
                    <span className={style.step3}>Step 1: On the Gift Store tab, choose what brand to buy from, then select the eGift that you want to purchase.</span>
                    <span className={style.step3}>Step 2: On the next screen, set the quantity of eGift and then select the contact that you want to send the gift to.</span>
                    <span className={style.step3}>Step 3: You have the option to add the product to cart by tapping <strong>Add to Cart</strong> button or <strong>Buy Now</strong> if you want to proceed with the payment.</span>
                    <span className={style.step3}>Step 4: On the Confirmation Screen, review your details then tap <strong>Pay</strong>.</span>
                    <span className={style.step3}>Step 5: Enter 6-digit One-Time-Password for verification.</span>
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown24} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     What is request money/bills/load? How do I make a request? 
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen24 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage3} />
                    </div>
                    </div>
                    {isDropdownOpen24 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <div className={style.step2}>
                    <span className={style.step}>
                    Make a request to another trilLOAN user to send your money, pay for your bills, or pay for your load purchase: </span>
                        </div>
                    <span className={style.step3}>-On the send money form, select Request on Send/Request.</span>
                    <span className={style.step3}>- the pay bills form, select Request Pay Bill on Pay/Request.</span>
                    <span className={style.step3}>-On the buy load form, select Request Buy Load on Buy/Request.</span>
                   
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown25} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     What are the fees or charges for transaction on the app? 
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen25 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen25 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >trilLOAN has a base convenience fee of 10.00 for most transactions. However, additional charges may incur depending on some partners.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown26} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     Is sending money transferred in real time?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen26 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen26 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Yes, you can send and receive money in real time via InstaPay.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown27} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     Can I still receive money if I don't have linked account?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen27 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen27 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Yes, you can still receive money through bank transfer by providing your bank details to the sender or through our partner remittance or pay out centers.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown28} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     How long does it take for my bill to be posted?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen28 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} /> 
                    </div>
                    </div>
                    {isDropdownOpen28 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Bills payment made through trilLOAN will be posted within 3 working days after the date of payment. We highly encourage users to pay their bills before the due date to avoid overdue payments.</span>
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown29} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     How can the recipient view the eGift on trilLOAN?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen29 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} />
                    </div>
                    </div>
                    {isDropdownOpen29 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Recipients will receive a notification in chat and e-mail where they can find the link to view the eGift code of the product.</span>
                    </div>
                </div>
                )} 
                    </div> 

                   <div className={style.dropdown} onClick={toggleDropdown30} >
                   <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     Why can't I transact on the app?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen30 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} />
                    </div>
                    </div>
                    {isDropdownOpen30 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                   
                    <div className={style.step2}>
                    <span className={style.step}>
                    Here are the possible reasons why you can't transact on trilLOAN:</span>
                        </div>
                    <span className={style.step3}>- You have not set up your payment method. Add a linked account or credit/debit card to transact.</span>
                    <span className={style.step3}>- Your device may not be connected to the internet. Kindly check your network, and try again.</span>
                    <span className={style.step3}>- The bank, biller, or network provider may be experiencing down time or system maintenance that could prevent you from transacting.</span>
                    <span className={style.step3}>- You may have exceeded your daily transaction limit. Not Yet Registered can transact up to a total of P5,000 a day, while Registered and Verified users limit is P50,000. You may try transacting again when your daily limit resets the next day.</span>
                    <span className={style.step3}>- If your account is Registered, you may have exceeded your account's total transaction limit or your account has reached one year of use, whichever comes first. The total transaction limit for Not Yet Registered users is P90,000, while Registered users is P100,000. Upgrade your account to Verified to continue transacting.  </span>
                    
                    </div>
                </div>
                )}
                    </div> 

                    <div className={style.dropdown} onClick={toggleDropdown31} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     What does Pay Out Center mean?
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen31 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} />
                    </div>
                    </div>
                    {isDropdownOpen31 && (
                 <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                    <span >Pay Out Centers are cash out outlets partnered with trilLOAN. These are physical outlet stores where people receive or pick up cash when you send via pay out center. If you need to send money to someone without a trilLOAN account or without a bank account, you can choose to send them cash through pay out centers.</span>
                    </div>
                </div>
                )}  
                    </div> 

                    <div className={style.dropdownlast} onClick={toggleDropdown32} >
                    <div className={style.mother}>
                        <div className={style.spandiv}>        
                     <span className={style.spanindropdown} >   
                     Can't find the answer you're looking for? 
                    </span>
                    </div>
                    <div className={style.imagediv}>
                    <img src={isDropdownOpen32 ? chevron2 : chevron} alt="Chevron" className={style.chevronimage2} />
                    </div>
                    </div>
                    {isDropdownOpen32 && (
                    <div className={style.dropdownContent}>
                    <div className={style.line}></div>
                    <div className={style.descriptiondiv}>
                   
                    <span className={style['method-title']}>Contact our trilLOAN Support team so that we may be able to address your questions of concerns.</span>
                    <span className={style['method-title']}>You may reach us by:</span>
                    
                    <span className={style.step3}>- Submit a ticket on the app;</span>
                    <span className={style.step3}>- Message trilLOAN Support through Live Chat;</span>
                    <span className={style.step3}>- Send us an email to support@paychat.ph, or;</span>
                    <span className={style.step3}>- Call our landline at 02-7005-3458. </span>
                    
                    </div>
                </div>
                )} 
                    </div> 
                    </div> 
                    
            
        </div>
        
        <Element name="section8">
          <Footer id="eight-section" />
        </Element>
        </>
    )
}

export default FAQs;
import style from './policy.module.css'
import Navbar  from '../components/navbar';
import Footer from '../components/footer';
import { Element } from 'react-scroll';

function Policy () {

    return (
        <>
        <Navbar />
        <div className={style.policy}>
        <div className={style.layeronediv}>
            <div className={style.layeronetitle}>
                <p>
                TRILLOAN INC. 
                </p>
                <p>
                PRIVACY POLICY
                </p>
            </div>   
            </div>   

            <div className={style.layeronetitlewide}>
                        <p>
                        TRILLOAN INC.  PRIVACY POLICY    
                        </p>
                       
                </div>   
           
            <div className={style.policymother}>
            <div className={style.layeronetitle2}>
                    <span>
                    Our Approach to Privacy 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    To provide you with the best customer experience, TrilLoan Inc. is committed to protecting your privacy and staying transparent with the way we handle your personal information. Our privacy policy will help you understand how we collect, use, store, and share and protect the personal information you have entrusted us. 
                    </span>
                </div>   

                <div className={style.layeronetitle2}>
                    <span>
                    Privacy Policy
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    Our privacy policy values and protects your personal information under the Data Privacy Act of 2012 and the laws of the Philippines. Personal information simply means any information or data that can be used to distinguish, identify or contact you. 
                    </span>
                </div>   

                <div className={style.layeronetitle2}>
                    <span>
                    Our Privacy Policy explains: 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    • Why we collect personal information 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                   • What kind information we collect 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    • Where we get this information 
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    • Who we share your information with  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    • How we protect your information  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    • Your choices, including how you can check and update any personal information we have about you  
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    • What happens if there are changes in our privacy policy 
                    </span>
                </div> 

                <div className={style.layeronetitle3}>
                    <span>
                    As a condition for use of our services, you are consenting to the Privacy Policy of trilLOAN Inc. (we, our, us) and its partners, and the collection, use, access, transfer, storage and processing of your personal information as described in this Privacy Policy.
                    </span>
                </div> 
                
                <div className={style.layeronetitle2}>
                    <span>
                    Why we collect personal information?  
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    To improve our services, it is important that we collect, use, process and analyze your personal information when it is reasonable and necessary. When we can, we aggregate and anonymize this information such that you are never identified as an individual. In particular, we are using your information to: 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    1. Manage your account, provide customer care activities, monitor the quality and security of the network, train our staff, and provide services timely and efficiently; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    2. Send commercial and promotional advertisements, loyalty and rewards offers, surveys, customer care and aftersales communications, and other broadcast push messages; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    3. Share your personal data with third-party content providers or other third-party services for which you sign up;  
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    4. Conduct analysis for research and marketing initiatives, including the generation of anonymized or non-personally identifiable location and mobility data to show footfall, location patterns, crowd density, and other information that may be relevant for marketing purposes; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    5. Generate statistical insights based on usage/network information to assist the Government in planning for healthcare, disaster, and other similar initiatives; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    6. Run credit scoring programs and initiatives, including but not limited to, providing information to the Credit Information Corporation in accordance to Republic Act No. 9501, otherwise known as the Credit Information System Act; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    7. Perform such other processing or disclosure that may be required under law or regulations. (i.e. KYC – Know Your Customer identity verification procedures as mandated by the pertinent BSP and AMLA policies). 
                    </span>
                </div>
                
                <div className={style.layeronetitle3}>
                    <span>
                    In cases where your separate and full consent is given, we are also processing your personal data to:
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    1. Create your personal profile based on your interests, preferences, mobility patterns from physical locations that you may visit, and other information that may be relevant for marketing and market research purposes; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    2. Share your personal profile based on your interests, preferences, and mobility patterns from physical locations that you may visit, to our business partners and third-party service providers; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    3. Compute your credit score based on data collected internally and share your credit score to third-parties to whom you specifically instruct us to share your credit score; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    4. Send you information on offers, promotions, and advertisements from our business partners and third-party service providers. 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    What type of information we collect?   
                    </span>
                </div>   

                <div className={style.layeronetitle3}>
                    <span>
                    We collect information to provide better services to all of our users. This may range from the most basic like being able to offer promos that best suites the user, to more complex use cases like which related services and digital content a user might find interesting & useful. This includes information such as: 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    Who you are   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    • Basic personal information like your name, date of birth, gender, nationality, address, and identity supporting documents such as SSS or passport numbers. You can check what we know about you by making a request to the trilLOAN Customer Support through the contact details provided below; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Your phone contacts list from the mobile phone where you have logged in (collected upon your consent which is given upon logging in to the app for the first time). 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Your credit card, bank and payment details; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                  • Your contact details like billing address, telephone number, mobile phone number, email address; 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    What you use our product/services for    
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    • Utility bills payments and government contributions  
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • ATM withdrawals, payments and transfers to third parties 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Account maintenance, including change in account information and contact details 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • The device you use when to access our Products and Services (such as your mobile handset, your computer system and platform) to customize our service for you 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • trilLOAN, Inc. does not look at the content of your text messages and neither does it listen to your calls, unless you are transacting with the company’s hotline 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    What you do with your trilLOAN Account    
                    </span>
                </div>  
                
                <div className={style.layeronetitle3}>
                    <span>
                    • This includes when and how much you reload or pay your bills; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • How often you access your account and why. 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    Where we get these information?   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    There are a few different ways we collect information. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Information that you personally give us 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Most of the personal information we have are those that you have given us yourself. You provide us personal information when you get in touch with us to ask about something, file a complaint or request for service, etc.
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Information we collect during our relationship with you 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Information we collect from other sources 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    We also collect information from commercially or publicly available sources like published directories and public documents. We may also obtain your personal information from third parties and from other sources which you have given consent for the disclosure of such information relating to you and where otherwise lawfully permitted. 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    Who we share your information with   
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    We will never share, rent or sell your personal information to third parties outside trilLOAN (or the group of companies of which trilLOAN is part of) except in limited circumstances as noted in this policy. In some instances, we may be required to disclose your personal information to our affiliates, partners and other third party agencies as part of business operations and the provision of our services. This means we might share your information with: 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Our partner companies, organizations, or agencies including their sub-contractors or prospective business partners that act as our service providers, contractors, professional advisers who help us provide our products and services, for example our couriers for bill delivery and our customer contact centers for our hotline operations; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Law enforcement and other government agencies; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • Other companies whom you have also given consent to share your information 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    • We will never share your local phone contacts list to anyone. We only use it to provide you information about which of your contacts are registered with trilLOAN. 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    How we protect your information 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The integrity, confidentiality and security of your information are particularly important to us. That’s why we strictly enforce our privacy policy within the company and we have implemented technological, organizational and physical security measures that are designed to protect your information from unauthorized access, use, alteration and disclosure. We also put in effect safeguards such as: 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    1. We keep and protect your information using a secured server behind a firewall, encryption and security controls; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    2. We restrict access to your information only to qualified and authorized personnel who hold your information with strict confidentiality;
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    3. We undergo regular audit and rigorous testing of our infrastructure’s security protocols to ensure your data is always protected; and 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    4. We let you update your information securely to keep our records accurate 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    We will retain your personal data throughout the subscription of our products and services, and for a maximum period of five (5) years after account closure. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Your choices, including how you can check and update any personal information we have about you. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    You are afforded certain rights in relation to your personal data under the Data Privacy Act of 2012, which includes the right to object to processing, the right to access your data, the right to modify inaccurate data, and the right to erasure or blocking of data. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    If you wish to have access to your personal information in our records; or you think that such personal information we have of you is incomplete, not up-to-date, or otherwise inaccurate; or prefer to opt-out of this privacy policy you may get in touch with our Technical support Team through the contact details provided below.
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Our Contact Details:
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Chat with us directly from the trilLOAN App or email: hello@paychat.ph
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    What happens when there are changes in this policy? 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    From time to time, trilLOAN may change this privacy policy to comply with government and regulatory requirements; to adapt to new technologies and protocols; to align with industry practices; or for other legitimate purposes. You will always be provided notice if these changes are material and, if we are required by law, we will also obtain your consent. 
                    </span>
                </div>

                <div className={style.layeronetitle2}>
                    <span>
                    Website Privacy Policy 
                    </span>
                </div>  

                <div className={style.layeronetitle3}>
                    <span>
                    The policies below are applicable to the Web site and affiliate sites of trilLOAN Inc. found at www.paychat.ph. Please be advised that the practices described in this Privacy Policy apply only to information gathered online at our Web site. They do not apply to information that you may submit to us offline or to Web sites maintained by other companies or organizations to which we may link or may have link through us. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    By visiting our Web site, you are accepting the practices described in our Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Web site. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN collects personal information, voluntarily submitted by visitors to the Web site, which enables us to respond to requests for publications, distribute e-newsletters, process employment inquiries and respond to requests for more information or assistance. trilLOAN adheres to the highest standards of ethical practices in all of our operations and is dedicated to protecting the privacy of all visitors to our Web site. Except as disclosed below, we do not sell, barter, give away, rent or permit anyone outside of trilLOAN to use your personal information. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    We occasionally use third-party agents, subsidiaries, affiliates and partners to perform functions such as creative design, marketing, analytics, programming, site maintenance, providing customer service, etc., on our behalf. These entities have access to the personal information needed to perform their functions and are contractually obligated to maintain the confidentiality and security of any personal information collected from the Web site. They are restricted from using, selling, distributing or altering these data in any way other than to provide the requested services to the Web site. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                   We may also use or disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                   (a) Conform to applicable law or comply with legal process served on us or the Website; 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    (b) Protect and defend our rights or property, the Web site or our users, and  
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    (c) Act under emergency circumstances to protect our safety and security and those of our affiliates, agents and the users of the Web site or the public in general.  
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    trilLOAN also collects anonymous information to help us tailor the Web site to visitor interests and concerns. We use "cookies" to help us understand which parts of our Web site are the most popular, where our visitors are going and how much time they spend there. The information that is gathered through cookies is used solely to assist in improving Web site design and function. This Web site is functional without the retention of cookies. You may elect to block cookies from this site through your browser settings. trilLOAN strives to protect the transmission of any information submitted by visitors. trilLOAN does not warrant that transmission of data will be completely secured, and any and all submissions are at the visitor's risk. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    This Web site may contain links to sites operated by third parties. Please be advised that the practices described in this Privacy Policy do not apply to information gathered through these other Web sites. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Please remember that any information that you may share in public areas, such as message boards or feedback sections, becomes public, and therefore this Privacy Policy does not apply to any information you choose to make public. Please be careful about what you disclose, and do not post any personal information that you expect to keep private. 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    The Web site is published in the Republic of the Philippines and is subject to laws of the Republic of the Philippines. trilLOAN may change this Privacy Policy to reflect, among others, changes in the way we 
                    </span>
                </div>

                <div className={style.layeronetitle3}>
                    <span>
                    Questions and comments should be directed to this email address: support@paychat.ph 
                    </span>
                </div>
                </div>
            </div>
            <Element name="section8">
          <Footer id="eight-section" />
        </Element>
            </>
    )

}
export default Policy;

import image from '../assets/first/about_us_image.png'

function FifthLayer() {

    return (
        <div className="fifthLayer">
        <div className="fifthLayerTwo">
            <p>Who are we?</p>
        </div>
         
            <div className="fifthLayerOne">
            <label>
                About Us
            </label>
            <span>
            trilLOAN Inc. is a Filipino-owned startup fintech company founded in 2018 that aims to disrupt the mobile payments industry. Envisioning itself to become a super app in the Philippines and beyond, trilLOAN will make every financial and personal connection possible among people, integrating banks, remittance centers, cooperatives, travel and lifestyle companies to name a few. Our goal is to always innovate and provide simplicity to our users when transacting through our platform. The team behind are composed of talented experts in the field of user interface, user experience, app development, data analytics and cyber security.
            </span>
            <img src={image} alt='123'/>
            </div>
            <div className="fifthLayerThree">
            <div className="fifthLayerFourth">
                <label>
                About Us
                </label>
                <span>
                trilLOAN Inc. is a Filipino-owned startup fintech company founded in 2018 that aims to disrupt the mobile payments industry. Envisioning itself to become a super app in the Philippines and beyond, trilLOAN will make every financial and personal connection possible among people, integrating banks, remittance centers, cooperatives, travel and lifestyle companies to name a few. Our goal is to always innovate and provide simplicity to our users when transacting through our platform. The team behind are composed of talented experts in the field of user interface, user experience, app development, data analytics and cyber security.
                </span>
            </div>
            <div className="fifthLayerFifth">
            <img src={image} alt='123'/>
            </div>
            </div>
        
        </div>
    )
}
export default FifthLayer;